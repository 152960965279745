import React from 'react';

import Footer from "./components/Footer/Footer";
import NavbarComponent from "./components/Navbar/NavbarComponent";
import Socialapp from "./components/SocialApps/Socialapp";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

function App() {
  return (
    <div className="main-container">
      <NavbarComponent />
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1, flexDirection: 'column'}}>
        <Socialapp />
        <Footer />
      </div>
    </div>
  );
}

export default App;
