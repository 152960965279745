import React from "react";
import { Navbar } from "react-bootstrap";
import QuestGridLogo from "../../Images/QuestGridLogo.png";
import "../../components/Navbar/Navbar.css";

function NavbarComponent() {
  return (
    <div className="header-container">
      <Navbar bg="light" expand="lg">
        <img className="quest-logo" src={QuestGridLogo} alt="logo" />
      </Navbar>
    </div>
  );
}

export default NavbarComponent;
