const allQuestions = [
  {
    topic: "What information do we collect?",
    id: "privacy-content",
  },
  {
    topic: "How do we process your information?",
    id: "process-info",
  },
  {
    topic: "When and with whom do we share your personal information?",
    id: "share-info",
  },
  {
    topic: "Do we use cookies and other tracking technologies?",
    id: "use-tech",
  },
  {
    topic: "How long do we keep your information?",
    id: "keep-info",
  },
  {
    topic: "How do we keep your information safe?",
    id: "info-safe",
  },
  {
    topic: "What are your privacy rights?",
    id: "privacy-rights",
  },
  {
    topic: "Controls for do-not-track features",
    id: "do-not-track",
  },
  {
    topic: "Do california residents have specific privacy rights?",
    id: "cali-p-rights",
  },
  {
    topic: "Do we make updates to this notice?",
    id: "make-updates",
  },
  {
    topic: "How can you contact us about this notice?",
    id: "contact-us",
  },
  {
    topic:
      "How can you review, update, or delete the data we collect from you?",
    id: "data-you",
  },
];

export default allQuestions;
