import privacyQuestions from "./allQuestions";
import termsTopics from "./termsTable";
import disclaimerTopics from "./disclaimer";

const pageLinks = [
  {
    name: "Privacy Notice",
    id: "privacy",
    sub: privacyQuestions,
  },
  {
    name: "Terms of Services",
    id: "terms",
    sub: termsTopics,
  },
  {
    name: "Disclaimer",
    id: "disclaimer",
    sub: disclaimerTopics,
  },
];

export default pageLinks;
