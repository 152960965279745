import React from "react";
import ReturnToTop from "../ReturnToTop/ReturnToTop";
import "./Disclaimer.css";

function Disclaimer() {

  return (
    <div className="dis" id="dis1">
      <div className="dis-heading">
        <h2 className="dis_heading-h2 heading-h2">Disclaimer</h2>
        <p className="dis_heading-time">Last updated March 04, 2022</p>
      </div>
      <div className="each-sec">
        <h2 className="sec-heading heading-h2">Website Disclaimer</h2>
        <p>
          The information provided by Questgrid, llc (“we,” “us”, or “our”) on{" "}
          <a className="content-link" href="https://questgrid.com/">
            https://questgrid.com/
          </a>{" "}
          (the “Site”) and our mobile application is for general informational
          purposes only. All information on the Site and our mobile application
          is provided in good faith, however we make no representation or
          warranty of any kind, express or implied, regarding the accuracy,
          adequacy, validity, reliability, availability or completeness of any
          information on the Site or our mobile application. UNDER NO
          CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU FOR ANY LOSS OR DAMAGE
          OF ANY KIND INCURRED AS A RESULT OF THE USE OF THE SITE OR OUR MOBILE
          APPLICATION OR RELIANCE ON ANY INFORMATION PROVIDED ON THE SITE AND
          OUR MOBILE APPLICATION. YOUR USE OF THE SITE AND OUR MOBILE
          APPLICATION AND YOUR RELIANCE ON ANY INFORMATION ON THE SITE AND OUR
          MOBILE APPLICATION IS SOLELY AT YOUR OWN RISK.
        </p>
      </div>
      <ReturnToTop hashUrl={"dis1"} category={"Disclaimer"} />
      <div className="each-sec" id="dis2">
        <h2 className="sec-heading heading-h2">External Links Disclaimer</h2>
        <p>
          The Site and our mobile application may contain (or you may be sent
          through the Site or our mobile application) links to other websites or
          content belonging to or originating from third parties or links to
          websites and features in banners or other advertising. Such external
          links are not investigated, monitored, or checked for accuracy,
          adequacy, validity, reliability, availability or completeness by us.
          WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR
          THE ACCURACY OR RELIABILITY OF ANY INFORMATION OFFERED BY THIRD-PARTY
          WEBSITES LINKED THROUGH THE SITE OR ANY WEBSITE OR FEATURE LINKED IN
          ANY BANNER OR OTHER ADVERTISING. WE WILL NOT BE A PARTY TO OR IN ANY
          WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND
          THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.
        </p>
      </div>
      <ReturnToTop hashUrl={"dis1"} category={"Disclaimer"} />
      <div className="each-sec" id="dis3">
        <h2 className="sec-heading heading-h2">Professional Disclaimer</h2>
        <p>
          The Site cannot and does not contain technology advice. The technology
          information is provided for general informational and educational
          purposes only and is not a substitute for professional advice.
          Accordingly, before taking any actions based upon such information, we
          encourage you to consult with the appropriate professionals. We do not
          provide any kind of technology advice. THE USE OR RELIANCE OF ANY
          INFORMATION CONTAINED ON THE SITE OR OUR MOBILE APPLICATION IS SOLELY
          AT YOUR OWN RISK.
        </p>
      </div>
      <ReturnToTop hashUrl={"dis1"} category={"Disclaimer"} />
    </div>
  );
}

export default Disclaimer;
