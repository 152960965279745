const disclaimerTopics = [
  {
    topic: "Website Disclaimer",
    id: "dis1",
  },
  {
    topic: "External Links Disclaimer",
    id: "dis2",
  },
  {
    topic: "Professional Disclaimer",
    id: "dis3",
  },
];

export default disclaimerTopics;
