import React, { useState, useEffect } from "react";
import "./PageLinks.css";
import { MenuIcon } from "@heroicons/react/outline";

import MenuButton from "../utilities/MenuButton/MenuButton";
import pageLinks from "../../data/pageLinks";
import logo from "../../Images/QuestGridLogo.png";
import PrivacyNotice from "../utilities/PrivacyNotice/PrivacyNotice";
import TermsOfServices from "../utilities/TermsOfServices/TermsOfServices";
import Disclaimer from "../utilities/Disclaimer/Disclaimer";
import { Link } from "react-router-dom";
import GoTop from "../utilities/GoTop/GoTop";

function PageLinks() {
  const [slideOpacity, setSlideOpacity] = useState("");
  const [winWidth, setWinWidth] = useState(window.innerWidth);

  useEffect(() => {
    setWinWidth(window.innerWidth);
    if (winWidth > 550) {
      setSlideOpacity("1");
    } else {
      setSlideOpacity("0");
    }
  }, []);

  const toggleSidebar = () => {
    if (winWidth < 551) {
      setSlideOpacity((prev) => {
        if (prev === "0") {
          return "1";
        } else {
          return "0";
        }
      });
    }
  };

  return (
    <>
      <div className="page-links" id="page-links-main">
        <GoTop />
        <div className="logo">
          <Link to="/">
            <img className="logo-img" src={logo} alt="" />
          </Link>
          <MenuIcon onClick={toggleSidebar} className="menu-icon" />
        </div>
        <div className="main">
          <div style={{ opacity: slideOpacity }} className="sidebar">
            <div className="sidebar_main">
              {pageLinks.map((page, index) => (
                <MenuButton
                  key={index}
                  page={page}
                  index={index}
                  certainClass={"menuBtn" + index}
                  toggleSidebar={toggleSidebar}
                />
              ))}
            </div>
          </div>
          <div className="content-area">
            <PrivacyNotice />
            <TermsOfServices />
            <Disclaimer />
          </div>
        </div>
      </div>
    </>
  );
}

export default PageLinks;
