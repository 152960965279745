import React from "react";
import "./GoTop.css";
import { ChevronUpIcon } from "@heroicons/react/solid";
import { useEffect } from "react";
import { useState } from "react";

function GoTop() {
  const [scrollPosition, setSrollPosition] = useState(0);
  const [showGoTop, setshowGoTop] = useState("goTopHidden");

  useEffect(() => {
    window.addEventListener("scroll", handleVisibleButton);
  });

  const handleVisibleButton = () => {
    const position = window.pageYOffset;
    setSrollPosition(position);

    if (scrollPosition > 50) {
      return setshowGoTop("goTop");
    } else if (scrollPosition < 50) {
      return setshowGoTop("goTopHidden");
    }
  };
  const scrollBack = () => {
    window.scrollTo({ left: 0, top: 0, behavior: "smooth" });
  };
  return (
    <>
      <div>
        <button className={showGoTop} onClick={scrollBack}>
          <ChevronUpIcon className="goTop__text" />
        </button>
      </div>
    </>
  );
}

export default GoTop;
