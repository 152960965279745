import React from "react";
import "./Socialapp.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faTwitter,
  faYoutube,
  faTwitch,
  faLinkedinIn,
  faTiktok,
  faReddit,
  faSnapchatGhost,
  faPinterest,
  faSpotify,
  faDiscord,
} from "@fortawesome/free-brands-svg-icons";
import QuestGridLogo from "../../Images/QuestGridLogo.png";
import { Image } from "react-bootstrap";
import configData from "../../config/config.json";
import onlyfans from "../../Images/onlyfans.png";
import applemusic from "../../Images/applemusic.png";

export default function Socialapp() {
  return (
    <div className="social-container">
      <Image
        className="logo"
        src={QuestGridLogo}
        alt="logo"
        fluid
        width="400" 
        hight="70"
      />

      <p className="mid-text">
        {" "}
        "A decentralized open-world networking platform."
      </p>
      <div className="social-links">
        <a
          href={configData.YOUTUBE_URL}
          target="_blank"
          rel="noreferrer"
          className=" youtube social"
        >
          <FontAwesomeIcon icon={faYoutube} size="2x" />
        </a>
        <a
          href={configData.FACEBOOK_URL}
          target="_blank"
          rel="noreferrer"
          className=" facebook social"
        >
          <FontAwesomeIcon icon={faFacebook} size="2x" />
        </a>
        <a
          href={configData.Twitter_URL}
          target="_blank"
          rel="noreferrer"
          className=" twitter social"
        >
          <FontAwesomeIcon icon={faTwitter} size="2x" />
        </a>
        <a
          href={configData.INSTAGRAM_URL}
          target="_blank"
          rel="noreferrer"
          className=" insta social"
        >
          <FontAwesomeIcon icon={faInstagram} size="2x" />
        </a>
        <a
          href={configData.Twitch_URL}
          target="_blank"
          rel="noreferrer"
          className=" twitch social"
        >
          <FontAwesomeIcon icon={faTwitch} size="2x" />
        </a>
        <a
          href={configData.LINKEDIN_URL}
          target="_blank"
          rel="noreferrer"
          className=" linkedin social"
        >
          <FontAwesomeIcon icon={faLinkedinIn} size="2x" />
        </a>
        <a
          href={configData.tiktok_URL}
          target="_blank"
          rel="noreferrer"
          className=" tiktok social"
        >
          <FontAwesomeIcon icon={faTiktok} size="2x" />
        </a>
        <a
          href={configData.Reddit_URL}
          target="_blank"
          rel="noreferrer"
          className=" reddit social"
        >
          <FontAwesomeIcon icon={faReddit} size="2x" />
        </a>
        <a
          href={configData.Snapchat_URL}
          target="_blank"
          rel="noreferrer"
          className=" snapchat social"
        >
          <FontAwesomeIcon icon={faSnapchatGhost} size="2x" />
        </a>
        <a
          href={configData.PINTEREST_URL}
          target="_blank"
          rel="noreferrer"
          className=" pinterest social"
        >
          <FontAwesomeIcon icon={faPinterest} size="2x" />
        </a>
        <a
          href={configData.ONLYFANS_URL}
          target="_blank"
          rel="noreferrer"
          className="onlyfans social"
        >
          <img src={onlyfans} alt="onlyfans" style={{ height: "2rem", width: "2rem", verticalAlign: "sub" }}/>
        </a>
        <a
          href={configData.APPLEMUSIC_URL}
          target="_blank"
          rel="noreferrer"
          className="applemusic social"
        >
          <img src={applemusic} alt="applemusic" style={{ height: "2rem", width: "2rem", verticalAlign: "sub" }}/>
        </a>
        <a
          href={configData.SPOTIFY_URL}
          target="_blank"
          rel="noreferrer"
          className=" spotify social"
        >
          <FontAwesomeIcon icon={faSpotify} size="2x" />
        </a>
        <a
          href={configData.DISCORD_URL}
          target="_blank"
          rel="noreferrer"
          className=" discord social"
        >
          <FontAwesomeIcon icon={faDiscord} size="2x" />
        </a>
      </div>
    </div>
  );
}
