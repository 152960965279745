import React from 'react';
import { Link } from "react-router-dom";

import "./Footer.css";
import AppStoreButton from "./AppStoreButton";

export default function Footer() {
    return (
        <div className="links-div">
            <AppStoreButton width="120" />
            <p>Est. 2020</p>
            <Link className="privacy-terms-link" to="/privacy">
                Privacy & Terms of Service
            </Link>
        </div>
    );
}
