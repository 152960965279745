const termsTopics = [
  {
    topic: "The Application",
    id: "term1",
  },
  {
    topic: "Scope of License",
    id: "term2",
  },
  {
    topic: "Technical Requirements",
    id: "term3",
  },
  {
    topic: "Maintenance and Support",
    id: "term4",
  },
  {
    topic: "Use of Data",
    id: "term5",
  },
  {
    topic: "User-Generated Contributions",
    id: "term6",
  },
  {
    topic: "Contribution License",
    id: "term7",
  },
  {
    topic: "Liability",
    id: "term8",
  },
  {
    topic: "Warranty",
    id: "term9",
  },
  {
    topic: "Product Claims",
    id: "term10",
  },
  {
    topic: "Legal Compliance",
    id: "term11",
  },
  {
    topic: "Contact Information",
    id: "term12",
  },
  {
    topic: "Termination",
    id: "term13",
  },
  {
    topic: "Third-Party Terms of Agreements and Beneficiary",
    id: "term14",
  },
  {
    topic: "Intellectual Property Rights",
    id: "term15",
  },
  {
    topic: "Applicable Law",
    id: "term16",
  },
  {
    topic: "Miscellaneous",
    id: "term17",
  },
];

export default termsTopics;
